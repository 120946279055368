import {
  productGroupMap,
  type ProductType,
} from 'configs';

const getProductsByStoryblokFilters = <T extends string | ProductType>(inputArray: T[]): T[] => {
  const result: T[] = [];

  inputArray.forEach((item) => {
    const category = productGroupMap.find((cat) => cat.name === item);
    if (category) {
      result.push(...(category.products as unknown as T[]));
    } else if (productGroupMap.some((cat) => cat.products.includes(item as unknown as ProductType))) {
      result.push(item);
    }
  });

  return result;
};

export { getProductsByStoryblokFilters };
